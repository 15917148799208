import { NavigationRoutes } from "@/components/navigation/constants";
import LitigeProduct from "@/components/product/LitigeProduct";
import { ScrollArea } from "@/components/ui/scroll-area";
import { RootState } from "@/store/store";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ReturnLitigeProductDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const litigeDetails = useSelector((state: RootState) => state.litige.litige);
  const return_ = useSelector((state: RootState) => state.return.return);

  useEffect(() => {
    if (
      return_.numTournee === null ||
      return_.numTournee === undefined ||
      litigeDetails.numLitige === null ||
      litigeDetails.numLitige === undefined
    ) {
      navigate(NavigationRoutes.DASHBOARD);
    }
  }, []);
  return (
    <>
      <div className="px-4 py-4 flex items-start justify-between gap-2 flex-row ">
        <p className="text-lg font-semibold">
          N° de retour: {return_.numTournee && return_.numTournee} - Litige N°
          {litigeDetails && litigeDetails.numLitige}
        </p>
        <p className="text-lg font-semibold">
          Vendeur: {return_.sellerName && return_.sellerName}
        </p>
      </div>

      <ScrollArea className="flex-grow px-2">
        {litigeDetails.products &&
          litigeDetails.products.map((product, index) => (
            <LitigeProduct key={index} product={product} />
          ))}
      </ScrollArea>
    </>
  );
};

export default ReturnLitigeProductDetailsPage;
