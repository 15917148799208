import { LitigeDetails } from "@/interfaces/Litige";
import { RootState } from "@/store/store";
import { formateDate } from "@/utils/date-utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductCounter from "../product-counter/ProductCounter";
import { PRIMARY_COLOR } from "@/constants/constants";
import { NavigationRoutes } from "../navigation/constants";
import { setLitige } from "@/store/reducers/litigeReducer";
import { useNavigate } from "react-router-dom";

interface LitigeProps {
  litige: LitigeDetails;
}

const Litige: React.FC<LitigeProps> = ({ litige }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const return_ = useSelector((state: RootState) => state.return.return);
  const handleLitigeClick = () => {
    dispatch(setLitige(litige));
    navigate(NavigationRoutes.LITIGE_PRODUCT_DETAILS);
  };
  return (
    <div
      className={`w-full flex flex-col rounded-lg shadow-md bg-white hover:bg-gray-50 my-2 border border-${PRIMARY_COLOR} border-2 p-3 space-y-2 cursor-pointer  transition-colors duration-200`}
      onClick={handleLitigeClick}
    >
      <div className="flex flex-col sm:flex-row sm:items-center justify-between w-full">
        <h2 className="text-lg font-semibold text-gray-800 mb-1 sm:mb-0">
          Litige N° {litige.numLitige}
        </h2>
      </div>
      <div className="flex items-center justify-between">
        <h3 className="text-sm font-bold text-gray-700">
          {return_.sellerName}
        </h3>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <ProductCounter productCount={litige.count_product} />
        <p className="text-xs text-gray-500">{formateDate(litige.createdon)}</p>
      </div>
    </div>
  );
};

export default Litige;
