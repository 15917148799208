import axios, { AxiosResponse } from "axios"; // Assuming Axios is installed
import {
  list_tournees_annuler_vendeur_url,
  list_tournees_entrepot_url,
  list_tournees_hub_url,
  list_tournees_livreur_url,
  list_tournees_vendeur_url,
} from "../constants/urls";
import { store } from "../store/store";
import { defaultHeadersWithToken } from "../constants/constants";
import { ServerResponse_ } from "@/interfaces/Server";
import { ReturnList } from "@/interfaces/Return";

export type Payload = {
  page_number: number;
  page_size: number;
  keyword: string;
};

export const getSellerReturns = async (payload: Payload) => {
  const accessToken = store.getState().authentication.accessToken;

  try {
    const response: AxiosResponse<ServerResponse_<ReturnList>> = await axios({
      url: list_tournees_vendeur_url,
      headers: defaultHeadersWithToken(accessToken),
      data: {
        page_number: payload.page_number,
        page_size: payload.page_size,
        keyword: payload.keyword,
      },
      method: "POST",
    });

    return response;
  } catch (error: any) {
    console.error(error.description);
  }
};

export const getWarehouseReturns = async (payload: Payload) => {
  const accessToken = store.getState().authentication.accessToken;

  try {
    const response: AxiosResponse<ServerResponse_<ReturnList>> = await axios({
      url: list_tournees_entrepot_url,
      headers: defaultHeadersWithToken(accessToken),
      data: {
        page_number: payload.page_number,
        page_size: payload.page_size,
        keyword: payload.keyword,
      },
      method: "POST",
    });

    return response;
  } catch (error: any) {
    console.error(error.description);
  }
};

export const getDeliveryReturns = async (payload: Payload) => {
  const accessToken = store.getState().authentication.accessToken;
  try {
    const response: AxiosResponse<ServerResponse_<ReturnList>> = await axios({
      url: list_tournees_livreur_url,
      headers: defaultHeadersWithToken(accessToken),
      data: {
        page_number: payload.page_number,
        page_size: payload.page_size,
        keyword: payload.keyword,
      },
      method: "POST",
    });

    return response;
  } catch (error: any) {
    console.error(error.description);
  }
};

export const getHubReturns = async (payload: Payload) => {
  const accessToken = store.getState().authentication.accessToken;

  try {
    const response: AxiosResponse<ServerResponse_<ReturnList>> = await axios({
      url: list_tournees_hub_url,
      headers: defaultHeadersWithToken(accessToken),
      data: {
        page_number: payload.page_number,
        page_size: payload.page_size,
        keyword: payload.keyword,
      },
      method: "POST",
    });

    return response;
  } catch (error: any) {
    console.error(error.description);
  }
};

export const getReturnsCanceledBySeller = async (payload: Payload) => {
  const accessToken = store.getState().authentication.accessToken;

  try {
    const response: AxiosResponse<ServerResponse_<ReturnList>> = await axios({
      url: list_tournees_annuler_vendeur_url,
      headers: defaultHeadersWithToken(accessToken),
      data: {
        page_number: payload.page_number,
        page_size: payload.page_size,
        keyword: payload.keyword,
      },
      method: "POST",
    });

    return response;
  } catch (error: any) {
    console.error(error.description);
  }
};
