export const PRIMARY_COLOR: string = "#0A4E54";
export const SECONDARY_COLOR: string = "#00afab";
export const BACKGROUND_COLOR: string = "#086484";
export const TINT_COLOR: string = "#3b7176";
export const DISABLED_COLOR: string = "#cedcdd";
export const SELLER_ROLE: string = "SELLER_MANAGER";
export const DELIVERY_ROLE: string = "ENTREPOT_RAMASSAGE";
export const WAREHOUSE_ROLE: string = "ENTREPOT_PICKER";
export const HUB_ROLE: string = "AGENT_HUB_REPRISE";
export const RECEIVED_STATUS: string = "RECEIVED";
export const CREATED_STATUS: string = "CREATED";
export const DELIVERED_STATUS: string = "DELIVERED";
export const LITIGE_STATUS: string = "LITIGE";
export const DELETED_STATUS: string = "DELETED";
export const TOAST_COLOR: string = "#05272a";
export const LITIGE_SCREEN: string = "LitigeScreen";
export const DELIVERY_VALIDATION_SCREEN: string = "DeliveryValidationScreen";
export const CREATE_RETURN_SCREEN: string = "AddReturnItemScreen";
export const PERMISSION_DENIED_MESSAGE: string =
  "Permission refusée pour accéder à la caméra.";
export const USER_CANCELLED_MESSAGE: string =
  "Utilisateur a annulé la capture.";
export const DEFAULT_TOAST_MESSAGE: string = "success";
export const DOAWNLOADING_STATUS: string = "DOWNLOADING";
export const DOWNLOADED_STATUS: string = "DOWNLOADED";
export const DOWNLOADING_MESSAGE: string = "Téléchargement en cours";
export const MILKRUN_ID: number = 8;
export const PICKUP_CONFIRMATION_DIALOG: string = "PICKUP_CONFIRMATION_DIALOG";
export const DELETE_CONFIRMATION_DIALOG: string = "DELETE_CONFIRMATION_DIALOG";
export const LITIGE_CONFIRMATION_DIALOG: string = "LITIGE_CONFIRMATION_DIALOG";
export const CANCELED_RETURN_CONFIRMATION_DIALOG: string =
  "CANCELED_RETURN_CONFIRMATION_DIALOG";
export const DELIVERY_CONFIRMATION_BY_PJ_DIALOG: string =
  "DELIVERY_CONFIRMATION_BY_PJ_DIALOG";
export const DELIVERY_CONFIRMATION_DIALOG: string =
  "DELIVERY_CONFIRMATION_DIALOG";
export const DOWNLOAD_SUCCESS_MESSAGE: string =
  "Fichier enregistré sous Téléchargements";
export const defaultHeaders: any = {
  "Content-Type": "application/json",
  version: "1.0.0",
};

export const defaultHeadersWithToken = (accessToken: string) => {
  return {
    "Content-Type": "application/json",
    version: "1.0.0",
    Authorization: `Bearer ${accessToken}`,
  };
};

export enum RequestModes {
  VENDABLE = "VENDABLE",
  ENTREPOT = "ENTREPOT",
  NON_VENDABLE = "NON_VENDABLE",
}

export const DEFAULT_PAGE_SIZE: number = 10;
