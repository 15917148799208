// store.ts
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import authReducer, { authPersistConfig } from "./reducers/authReducer";
import searchReducer from "./reducers/searchReducer";
import { Authentication } from "@/interfaces/Authentification"; // Import your Authentication interface
import returnProductsReducer, {
  returnProductsPersistConfig,
  ReturnProductsState,
} from "./reducers/returnProductsReducer";
import dialogReducer from "./reducers/dialogReducer";
import refreshReducer from "./reducers/refreshReducer";
import returnHeaderReducer, {
  returnHeaderPersistConfig,
  ReturnHeaderState,
} from "./reducers/returnHeaderReducer";
import returnReducer, {
  returnPersistConfig,
  ReturnState,
} from "./reducers/returnReducer";
import litigeReducer, {
  litigePersistConfig,
  LitigeState,
} from "./reducers/litigeReducer";

// Create root reducer with proper typing
const rootReducer = combineReducers({
  authentication: persistReducer<Authentication>(
    authPersistConfig,
    authReducer
  ),
  returnProducts: persistReducer<ReturnProductsState>(
    returnProductsPersistConfig,
    returnProductsReducer
  ),
  returnHeader: persistReducer<ReturnHeaderState>(
    returnHeaderPersistConfig,
    returnHeaderReducer
  ),
  return: persistReducer<ReturnState>(returnPersistConfig, returnReducer),
  litige: persistReducer<LitigeState>(litigePersistConfig, litigeReducer),
  dialog: dialogReducer,
  search: searchReducer,
  refresh: refreshReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

export const persistor = persistStore(store);

// Export types
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
