import axios, { AxiosResponse } from "axios";
import { store } from "../store/store";
import { defaultHeadersWithToken } from "../constants/constants";
import { ServerResponse_ } from "@/interfaces/Server";
import { ReturnRequest } from "@/interfaces/Return";
import {
  return_request_entrepot_url,
  return_request_non_vendable_url,
  return_request_vendable_url,
} from "@/constants/urls";

export async function getRequests_NonVendable(
  sellerId: number
): Promise<AxiosResponse<ServerResponse_<ReturnRequest[]>>> {
  const accessToken = store.getState().authentication.accessToken;
  try {
    const response: AxiosResponse<ServerResponse_<ReturnRequest[]>> =
      await axios({
        url: return_request_non_vendable_url + sellerId,
        headers: defaultHeadersWithToken(accessToken),
        method: "GET",
      });
    return response;
  } catch (error: any) {
    return error.response;
  }
}

export async function getRequests_Vendable(
  sellerId: number
): Promise<AxiosResponse<ServerResponse_<ReturnRequest[]>>> {
  const accessToken = store.getState().authentication.accessToken;
  try {
    const response: AxiosResponse<ServerResponse_<ReturnRequest[]>> =
      await axios({
        url: return_request_vendable_url + sellerId,
        headers: defaultHeadersWithToken(accessToken),
        method: "GET",
      });
    return response;
  } catch (error: any) {
    return error.response;
  }
}

export async function getRequests_Entrepot(
  sellerId: number
): Promise<AxiosResponse<ServerResponse_<ReturnRequest[]>>> {
  const accessToken = store.getState().authentication.accessToken;
  try {
    const response: AxiosResponse<ServerResponse_<ReturnRequest[]>> =
      await axios({
        url: return_request_entrepot_url + sellerId,
        headers: defaultHeadersWithToken(accessToken),
        method: "GET",
      });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
