import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import NotFoundPage from "@/pages/NotFoundPage";
import { store, persistor, RootState } from "@/store/store";
import { PersistGate } from "redux-persist/integration/react";
import Layout from "@/pages/Layout";
import Dashboard from "@/pages/Dashboard";
import LoginPage from "@/pages/LoginPage";
import ReturnDetailsPage from "@/pages/ReturnDetailsPage";
import ReturnCreatePage from "@/pages/ReturnCreatePage";
import NotAuthorizedPage from "@/pages/NotAuthorizedPage";
import ReturnPickUpValidationPage from "@/pages/ReturnPickUpValidationPage";
import ReturnDeliveryValidationPage from "@/pages/ReturnDeliveryValidationPage";
import { NavigationRoutes } from "./constants";
import ReturnLitigePage from "@/pages/ReturnLitigePage";
import ReturnValidationByPJPage from "@/pages/ReturnValidationByPJ";
import { unauthenticate } from "@/store/reducers/authReducer";
import ReturnRequestLitigePage from "@/pages/ReturnRequestLitigePage";
import ReturnLitigeDetailsPage from "@/pages/ReturnLitigeDetailsPage";
import ReturnLitigeProductDetailsPage from "@/pages/ReturnLitigeProductDetailsPage";

const RootNavigation: React.FC = () => {
  interface ProtectedRouteProps {
    children: React.ReactNode;
  }

  const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.authentication);
    function isTokenExpired() {
      const expiryDate = new Date(user.expiresOn);
      const currentDate = new Date();
      return currentDate > expiryDate;
    }

    if (user.accessToken === "") {
      return <Navigate to={NavigationRoutes.NOT_AUTHORIZED} replace />;
    }
    if (isTokenExpired()) {
      dispatch(unauthenticate());
      return <Navigate to={NavigationRoutes.ROOT} replace />;
    }

    return <>{children}</>;
  };
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            <Route
              path={NavigationRoutes.DASHBOARD}
              element={
                <ProtectedRoute>
                  <Layout headerTitle="Dashboard">
                    <Dashboard />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={NavigationRoutes.RETURN_DETAILS}
              element={
                <ProtectedRoute>
                  <Layout headerTitle="Détail du retour">
                    <ReturnDetailsPage />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={NavigationRoutes.LITIGE_DETAILS}
              element={
                <ProtectedRoute>
                  <Layout headerTitle="Détail du litige">
                    <ReturnLitigeDetailsPage />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={NavigationRoutes.LITIGE_PRODUCT_DETAILS}
              element={
                <ProtectedRoute>
                  <Layout headerTitle="Détail des produits du litige">
                    <ReturnLitigeProductDetailsPage />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={NavigationRoutes.RETURN_CREATE}
              element={
                <ProtectedRoute>
                  <Layout headerTitle="Créer un retour">
                    <ReturnCreatePage />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={NavigationRoutes.RETURN_PICK_UP_VALIDATION}
              element={
                <ProtectedRoute>
                  <Layout headerTitle="Validation du ramassage">
                    <ReturnPickUpValidationPage />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={NavigationRoutes.RETURN_DELIVERY_VALIDATION}
              element={
                <ProtectedRoute>
                  <Layout headerTitle="Validation de livraison">
                    <ReturnDeliveryValidationPage />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={NavigationRoutes.RETURN_DELIVERY_VALIDATION}
              element={
                <ProtectedRoute>
                  <Layout headerTitle="Validation de livraison">
                    <ReturnDeliveryValidationPage />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={NavigationRoutes.RETURN_LITIGE}
              element={
                <ProtectedRoute>
                  <Layout headerTitle="Litige">
                    <ReturnLitigePage />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={NavigationRoutes.PJ}
              element={
                <ProtectedRoute>
                  <Layout headerTitle="Validation de livraison par PJ">
                    <ReturnValidationByPJPage />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path={NavigationRoutes.REQUEST_LITIGE}
              element={
                <ProtectedRoute>
                  <Layout headerTitle="Demande de litige par demande">
                    <ReturnRequestLitigePage />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route path={NavigationRoutes.ROOT} element={<LoginPage />} />
            <Route
              path={NavigationRoutes.NOT_FOUND}
              element={<NotFoundPage />}
            />
            <Route
              path={NavigationRoutes.NOT_AUTHORIZED}
              element={<NotAuthorizedPage />}
            />
          </Routes>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
};

export default RootNavigation;
