import { Scaling } from "lucide-react";
import { useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";

interface ImagePreviewProps {
  imageUrl: string;
}

export default function ImagePreview({ imageUrl }: ImagePreviewProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <div className="relative group">
          <img
            src={imageUrl}
            alt="preview"
            className="w-28 h-28 object-cover border border-gray-300 rounded-md transition-transform duration-300 group-hover:scale-[1.02]"
          />
          <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 cursor-pointer flex items-center justify-center opacity-0 group-hover:opacity-100 rounded-md">
            <Scaling className="text-white w-8 h-8 drop-shadow-lg" />
          </div>
        </div>
      </DialogTrigger>
      <DialogContent className="max-h-[90vh] p-0" hasClose>
        <ScrollArea className="w-full h-full max-h-[calc(90vh-2rem)]">
          <div className="min-h-full min-w-full flex items-center justify-center p-4">
            <img
              src={imageUrl}
              alt="preview"
              className="max-w-full max-h-full object-contain rounded-md border-2"
            />
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}
