import { ReturnList } from "@/interfaces/Return";
import {
  DELIVERY_ROLE,
  HUB_ROLE,
  SELLER_ROLE,
  WAREHOUSE_ROLE,
} from "../constants/constants";

import {
  getDeliveryReturns,
  getHubReturns,
  getSellerReturns,
  getWarehouseReturns,
  Payload,
} from "../services/return-list.service";
import { AxiosResponse } from "axios";
import { ServerResponse_ } from "@/interfaces/Server";

export const fetchReturnList = async (
  setIsLoading: any,
  setReturnList: any,
  roles: string,
  payload: Payload
) => {
  try {
    setIsLoading(true);
    const roleMap: {
      [key: string]: () => Promise<
        AxiosResponse<ServerResponse_<ReturnList>> | undefined
      >;
    } = {
      [SELLER_ROLE]: () => getSellerReturns(payload),
      [DELIVERY_ROLE]: () => getDeliveryReturns(payload),
      [WAREHOUSE_ROLE]: () => getWarehouseReturns(payload),
      [HUB_ROLE]: () => getHubReturns(payload),
    };

    let fetchFunction = null;

    if (roles.includes(WAREHOUSE_ROLE)) {
      fetchFunction = roleMap[WAREHOUSE_ROLE];
    } else if (roles.includes(DELIVERY_ROLE)) {
      fetchFunction = roleMap[DELIVERY_ROLE];
    } else if (roles.includes(SELLER_ROLE)) {
      fetchFunction = roleMap[SELLER_ROLE];
    } else if (roles.includes(HUB_ROLE)) {
      fetchFunction = roleMap[HUB_ROLE];
    }

    if (fetchFunction) {
      const response = await fetchFunction();
      const returns = response?.data.data.list || [];

      setReturnList([...returns]);
    } else {
      setReturnList([]);
    }
  } catch (error) {
    setReturnList([]);
    // Display an error message to the user (optional)
  } finally {
    setIsLoading(false);
  }
};
