import axios, { AxiosResponse } from "axios";
import { store } from "../store/store";
import { defaultHeadersWithToken } from "../constants/constants";
import { ReturnToCreateViaRequest } from "@/interfaces/Return";
import { ServerResponse } from "@/interfaces/Server";
import {
  create_tournee_via_request_entrepot_url,
  create_tournee_via_request_non_vendable_url,
  create_tournee_via_request_vendable_url,
} from "@/constants/urls";

export async function createTourneeViaRequest_NonVendable(
  tournee: ReturnToCreateViaRequest
): Promise<AxiosResponse<ServerResponse>> {
  const accessToken = store.getState().authentication.accessToken;

  try {
    const response: AxiosResponse<ServerResponse> = await axios({
      url: create_tournee_via_request_non_vendable_url,
      headers: defaultHeadersWithToken(accessToken),
      data: tournee,
      method: "POST",
    });
    return response;
  } catch (error) {
    console.error("Error creating tournee:", error);
    return {} as AxiosResponse<ServerResponse>;
  }
}

export async function createTourneeViaRequest_Vendable(
  tournee: ReturnToCreateViaRequest
): Promise<AxiosResponse<ServerResponse>> {
  const accessToken = store.getState().authentication.accessToken;

  try {
    const response: AxiosResponse<ServerResponse> = await axios({
      url: create_tournee_via_request_vendable_url,
      headers: defaultHeadersWithToken(accessToken),
      data: tournee,
      method: "POST",
    });
    return response;
  } catch (error) {
    console.error("Error creating tournee:", error);
    return {} as AxiosResponse<ServerResponse>;
  }
}

export async function createTourneeViaRequest_Entrepot(
  tournee: ReturnToCreateViaRequest
): Promise<AxiosResponse<ServerResponse>> {
  const accessToken = store.getState().authentication.accessToken;

  try {
    const response: AxiosResponse<ServerResponse> = await axios({
      url: create_tournee_via_request_entrepot_url,
      headers: defaultHeadersWithToken(accessToken),
      data: tournee,
      method: "POST",
    });
    return response;
  } catch (error) {
    console.error("Error creating tournee:", error);
    return {} as AxiosResponse<ServerResponse>;
  }
}
