import React from "react";

interface LitigeTagProps {
  text: string | undefined;
}

const LitigeTag: React.FC<LitigeTagProps> = ({ text }) => {
  return (
    <div className="inline-flex items-center rounded-md px-2 py-1 bg-red-400">
      <p className="text-xs font-medium text-white">{text && text}</p>
    </div>
  );
};

export default LitigeTag;
