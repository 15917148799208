import { LitigeDetails } from "@/interfaces/Litige";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

export const litigePersistConfig = {
  key: "litige",
  storage,
  whitelist: ["litige"],
};

export interface LitigeState {
  litige: LitigeDetails;
}

const initialState: LitigeState = {
  litige: {} as LitigeDetails,
};

const litigeSlice = createSlice({
  name: "litige",
  initialState,
  reducers: {
    setLitige(state, action: PayloadAction<LitigeDetails>) {
      state.litige = action.payload;
    },
    resetLitige(state) {
      state.litige = {} as LitigeDetails;
    },
  },
});

export const { setLitige, resetLitige } = litigeSlice.actions;
export default litigeSlice.reducer;
