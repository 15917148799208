import React from "react";
import LitigeTag from "../tags/LitigeTag";
import { LitigeProduct } from "@/interfaces/Litige";
import ImagePreview from "../common/ImagePreview";

interface LitigeProductProps {
  product: LitigeProduct;
}
const LitigeProduct_: React.FC<LitigeProductProps> = ({ product }) => {
  return (
    <div className="w-full flex flex-col gap-4 sm:flex-row">
      <div className="w-full flex rounded-lg shadow-md  h-auto bg-white hover:bg-gray-100 my-2 border border-primary p-3 flex-row gap-4">
        <div className="flex  items-center space-x-2 justify-center  align-middle">
          <img src={product.image} alt="product" className="w-20 h-20" />
        </div>
        <div className="flex  justify-between w-full flex-col">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between w-full mb-2">
            <p className="text-base font-bold text-gray-700">
              {product.genCode}
            </p>
            <div className="flex flex-wrap items-center gap-2 mt-1 sm:mt-0">
              <LitigeTag text={product.motif.name} />
            </div>
          </div>
          <p className="text-sm text-gray-500 font-semibold">
            {product.productName}
          </p>
          <p className="text-sm text-gray-500 font-semibold text-right">
            Quantité: {product.quantity}
          </p>
        </div>
      </div>
      <div className="flex rounded-lg shadow-md bg-white border border-primary my-2 flex-row">
        {product.files.length > 0 && (
          <div className="flex flex-row gap-4 items-center justify-center px-2 py-2">
            {product.files.map((file, index) => (
              <ImagePreview key={index} imageUrl={file.urlPath} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LitigeProduct_;
