import axios, { AxiosResponse } from "axios";
import { litige_details_url } from "../constants/urls";
import { store } from "../store/store";
import { defaultHeadersWithToken } from "../constants/constants";
import { ServerResponse_ } from "@/interfaces/Server";
import { LitigeDetails } from "@/interfaces/Litige";

export async function getLitigeDetails(
  returnCode: string
): Promise<AxiosResponse<ServerResponse_<LitigeDetails[]>>> {
  const accessToken = store.getState().authentication.accessToken;
  try {
    const response: AxiosResponse<ServerResponse_<LitigeDetails[]>> =
      await axios({
        url: litige_details_url + `${returnCode}`,
        headers: defaultHeadersWithToken(accessToken),
        method: "GET",
      });
    return response;
  } catch (error) {
    return {} as AxiosResponse<ServerResponse_<LitigeDetails[]>>;
  }
}
