import Litige from "@/components/litige/Litige";
import { NavigationRoutes } from "@/components/navigation/constants";
import { ScrollArea } from "@/components/ui/scroll-area";
import { LitigeDetails } from "@/interfaces/Litige";
import { getLitigeDetails } from "@/services/litige-details.service";
import { RootState } from "@/store/store";
import { LoaderCircle } from "lucide-react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ReturnLitigeDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const return_ = useSelector((state: RootState) => state.return.return);
  const [litigeDetails, setLitigeDetails] = React.useState<LitigeDetails[]>([]);
  const [detailsLoading, setDetailsLoading] = React.useState<boolean>(false);

  const fetchLitigeDetails = async () => {
    try {
      if (return_.numTournee) {
        setDetailsLoading(true);
        const response = await getLitigeDetails(return_.numTournee.toString());
        if (response.status === 200 && response.data.success) {
          setLitigeDetails(response.data.data);
          console.log(response.data.data);
        }
        setDetailsLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDetailsLoading(false);
    }
  };

  useEffect(() => {
    if (return_.numTournee === null || return_.numTournee === undefined) {
      navigate(NavigationRoutes.DASHBOARD);
    }
  }, []);

  useEffect(() => {
    fetchLitigeDetails();
  }, [return_]);

  return (
    <>
      {!detailsLoading ? (
        <>
          <div className="px-4 py-4 flex items-start justify-between gap-2 flex-row ">
            <p className="text-lg font-semibold">
              N° de retour: {return_.numTournee && return_.numTournee}
            </p>
            <p className="text-lg font-semibold">
              Vendeur: {return_.sellerName && return_.sellerName}
            </p>
          </div>
          <ScrollArea className="flex-grow px-2">
            {litigeDetails &&
              litigeDetails.map((litige, index) => (
                <Litige key={index} litige={litige} />
              ))}
          </ScrollArea>
        </>
      ) : (
        <div className="flex items-center justify-center h-full">
          <LoaderCircle size={32} className="text-primary animate-spin" />
        </div>
      )}
    </>
  );
};

export default ReturnLitigeDetailsPage;
