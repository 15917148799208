import DeleteConfirmationDialog from "@/components/dialogs/DeleteConfirmationDialog";
import ReturnCreationDialog from "@/components/dialogs/ReturnCreationDialog";
import CanceledReturn from "@/components/return/CanceledReturn";
import Return from "@/components/return/Return";
import ReturnSkeleton from "@/components/return/ReturnSkeleton";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Toaster } from "@/components/ui/toaster";
import { DEFAULT_PAGE_SIZE, WAREHOUSE_ROLE } from "@/constants/constants";
import { IReturn } from "@/interfaces/Return";
import { getReturnsCanceledBySeller } from "@/services/return-list.service";
import { resetLitige } from "@/store/reducers/litigeReducer";
import { resetReturnHeader } from "@/store/reducers/returnHeaderReducer";
import { resetArticles } from "@/store/reducers/returnProductsReducer";
import { resetReturn } from "@/store/reducers/returnReducer";
import { RootState } from "@/store/store";
import { fetchReturnList } from "@/utils/return-list-fetching";

import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

interface DashboardProps {
  // Define any props for the Dashboard component here
}

const Dashboard: React.FC<DashboardProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const searchText = useSelector((state: RootState) => state.search.searchText);
  const [returnList, setReturnList] = useState<IReturn[]>([] as IReturn[]);
  const [canceledReturnList, setCanceledReturnList] = useState<IReturn[]>(
    [] as IReturn[]
  );
  const [isLoading, setIsLoading] = useState(true);
  const [currentListValue, setCurrentListValue] = useState<string>("normal");
  const [canceledListLoading, setCanceledListLoading] = useState(true);
  const { roles } = useSelector((state: RootState) => state.authentication);
  const isRefreshing = useSelector(
    (state: RootState) => state.refresh.isRefreshing
  );
  const [hasMore, setHasMore] = useState(true);
  const [, setHasMoreCanceled] = useState(true);

  // Refs for intersection observer
  const observer = useRef<IntersectionObserver>();
  const lastReturnElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  const fetchReturnsCancelled = async (page: number) => {
    try {
      setCanceledListLoading(true);
      const response = await getReturnsCanceledBySeller({
        keyword: searchText,
        page_number: page,
        page_size: DEFAULT_PAGE_SIZE,
      });
      if (response && response.data.success) {
        if (page === 1) {
          setCanceledReturnList(response.data.data.list);
        } else {
          setCanceledReturnList((prev) => [
            ...prev,
            ...response.data.data.list,
          ]);
        }
        setHasMoreCanceled(
          response.data.data.list.length === DEFAULT_PAGE_SIZE
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCanceledListLoading(false);
    }
  };

  const fetchReturns = async (page: number) => {
    try {
      setIsLoading(true);
      await fetchReturnList(
        setIsLoading,
        (newReturns: IReturn[]) => {
          if (page === 1) {
            setReturnList(newReturns);
          } else {
            setReturnList((prev) => [...prev, ...newReturns]);
          }
          setHasMore(newReturns.length === DEFAULT_PAGE_SIZE);
        },
        roles,
        {
          keyword: searchText,
          page_number: page,
          page_size: DEFAULT_PAGE_SIZE,
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const resetPersistStates = () => {
    dispatch(resetReturnHeader());
    dispatch(resetArticles());
    dispatch(resetReturn());
    dispatch(resetLitige());
  };

  useEffect(() => {
    setPageNumber(1);
    fetchReturns(1);
  }, [isRefreshing, searchText]);

  useEffect(() => {
    if (pageNumber > 1) {
      if (currentListValue === "normal") {
        fetchReturns(pageNumber);
      } else {
        fetchReturnsCancelled(pageNumber);
      }
    }
  }, [pageNumber]);

  useEffect(() => {
    resetPersistStates();
  }, []);

  useEffect(() => {
    if (currentListValue === "cancelled") {
      setPageNumber(1);
      fetchReturnsCancelled(1);
    }
  }, [currentListValue]);

  useEffect(() => {
    if (
      location.state &&
      location.state.tab === "cancelled" &&
      location.state.tab !== null
    ) {
      setCurrentListValue("cancelled");
      navigate(".", { state: null, replace: true });
    } else {
      setCurrentListValue("normal");
    }
  }, []);

  const renderReturnList = () => {
    if (!isLoading && returnList.length === 0) {
      return (
        <div className="text-center text-gray-500 font-sans">
          Aucune tournée disponible
        </div>
      );
    }

    return (
      <>
        {returnList.map((item, index) => (
          <div
            key={item.numTournee}
            ref={
              index === returnList.length - 1 ? lastReturnElementRef : undefined
            }
          >
            <Return return_={item} />
          </div>
        ))}
        {isLoading &&
          Array.from({ length: 3 }).map((_, index) => (
            <ReturnSkeleton key={`loading-${index}`} />
          ))}
      </>
    );
  };

  const renderCanceledList = () => {
    if (!canceledListLoading && canceledReturnList.length === 0) {
      return (
        <div className="text-center text-gray-500 font-sans">
          Aucune tournée annulée
        </div>
      );
    }

    return (
      <>
        {canceledReturnList.map((item, index) => (
          <div
            key={item.numTournee}
            ref={
              index === canceledReturnList.length - 1
                ? lastReturnElementRef
                : undefined
            }
          >
            <CanceledReturn return_={item} />
          </div>
        ))}
        {canceledListLoading &&
          Array.from({ length: 3 }).map((_, index) => (
            <ReturnSkeleton key={`loading-${index}`} />
          ))}
      </>
    );
  };

  return (
    <>
      <ScrollArea className="flex-grow px-2">
        <Tabs value={currentListValue} className="w-full px-2">
          {roles.includes(WAREHOUSE_ROLE) && (
            <TabsList className="flex-col h-full sm:flex-row w-full">
              <TabsTrigger
                className="w-full"
                value="normal"
                onClick={() => {
                  setCurrentListValue("normal");
                }}
              >
                Tournées vendeurs
              </TabsTrigger>
              <TabsTrigger
                className="w-full"
                value="cancelled"
                onClick={() => {
                  setCurrentListValue("cancelled");
                }}
              >
                Tournées annulées vendeur
              </TabsTrigger>
            </TabsList>
          )}
          <TabsContent value="normal">
            <div className="p-2">{renderReturnList()}</div>
          </TabsContent>
          <TabsContent value="cancelled">
            <div className="p-2">{renderCanceledList()}</div>
          </TabsContent>
        </Tabs>
      </ScrollArea>
      <ReturnCreationDialog />
      <DeleteConfirmationDialog />
      <Toaster />
    </>
  );
};

export default Dashboard;
